@import '/src/styles/settings/colors';

.wrapper {
    position: relative;
    top: 0;
    left: 0;
    // width: 100%;
    width: 100vw;
    height: unset;
}
.fixed_pos {
    position: fixed;
    background-color: $color-white;
    z-index: 2;
    border-bottom: 1px solid $color-light-gray-input;
}
.band {
    position: relative;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 78px;
}
.abs {
    position: absolute;
}
.band_sticky_hp {
    transform: translateY(-100px);
    position: fixed;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 8px 16px #00000029;
    border-bottom: 1px solid $color-light-gray-input;
    background-color: $color-white; 
    .main {
        .logo {
            display: none;
        }
    }
}
.band_sticky_hp_anim {
    transform: translateY(0);
    transition: all 0.3s ease;
}
.band_sticky_normal {
    transform: none;
}


.hp {
    &.band_sticky_hp {
        margin-top: 0;
        .main{
            justify-content: unset;
            gap: 16px;
            div:first-child{
                display: none;
            }
        }
    }
    // .main {
    //     padding: 0 100px;
    //     @media screen and (max-width: 1136px) {
    //         padding: 0 30px;
    //     }
    // }
}
.sr {
    // z-index: unset;
    // .main {
    //     padding: unset;
    //     @media screen and (max-width: 1099px) {
    //         padding: 0 20px;
    //     }
    // }
}
.ldp {
    margin-top: 20px;
    position: fixed;
    height: 50px;
    .logo {
        opacity: 0;
        visibility: 0;
        pointer-events: none;
    }
    .menu_symb_white {
        svg {
            margin-top: -50px;
        }
        path {
            stroke: rgb(0, 0, 0);
        }
    }
}
.ldp_band {
    z-index: 104;
    background-color: $color-black;
    .logo {
        opacity: unset;
        visibility: unset;
        pointer-events: unset;
    }
}
.ldp_band_margin {
    margin-top: 0;
}

.logo {}
.logo_invis {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.logo_birds,
.logo_text {
    flex: 0 0 auto;
    svg {
        transform: scale(0.66);
        transform-origin: top left;
    }
}
.logo_birds {
    width: 43px;
    height: 26px;
}
.logo_text {
    width: 133px;
    height: 29px;
}

.logo_white {
    path {
        fill: white;
    }
}

.main {
    position: relative;
    z-index: 1;
    padding: 0 20px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu_symb {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu_symb_white {
    path {
        stroke: white;
    }
}
.menu {
    display: block;
    // display: none;
    width: 100vw;
    height: calc(100vh - 78px);
    padding: 20px 20px 10px 20px;
    // transform: translateX(600px);
    transition: transform 0.3s ease;
    background-color: $color-light-gray-shade;
    overflow-y: auto;
}
// .menu_open {
//     display: block;
// }
// .menu_open_anim {
//     transform: translateX(0);
//     overflow-y: auto;
// }

.menu_open_body {
    overflow: hidden;
    .main {
        background-color: $color-white;
        // box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.06);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }
    .ldp{
        height: 78px;
    }
    .band_sticky_hp {
        .main {
            .logo {
                display: block;
            }
        }
    }
    .hp {
        .main {
            justify-content: space-between;
            gap: unset;
            div:first-child {
                display: block;
            }
        }
    }
}

.auth {
    padding: 20px;
    background-color: $color-white;
    border-radius: 0.5px solid $color-field-grey;
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
    border-radius: 15px;
    margin-bottom: 20px;
}
.auth_top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.auth_top_symbol {
    flex: 0 0 auto;
}
.auth_top_text {
    flex: 1;
    margin-left: 15px;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
}
.auth_middle {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
}
.auth_bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 0.5px solid #DFE2E5;
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
    border-radius: 100px;
    padding: 7px;
    background: $color-white;
}
.auth_bottom_login,
.auth_bottom_signup {
    all: unset;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
}
.auth_bottom_login {
    flex: 5;
    color: $color-cn-pink;
}
.auth_bottom_signup {
    flex: 6;
    color: $color-white;
    padding: 5px 0;
    background: linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%);
    border: 0.5px solid #DFE2E5;
    border-radius: 100px;
}

.menu_items {
    list-style-type: none;
    padding: 0 20px;
    margin-bottom: 34px;
    background-color: $color-white;
    border-radius: 0.5px solid $color-field-grey;
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
    border-radius: 15px;
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
}
.menu_item:not(:last-child) {
    border-bottom: 1px solid $color-light-gray-input;
}
.logout_btn,
.currency_btn {
    all: unset;
    margin: 0;
    width: 100%;
}
.menu_item a,
.menu_item button {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: inherit;
    line-height: inherit;
    font-variant: unset;
    text-transform: none;
    font-feature-settings: unset;
    color: unset;
}
.menu_item_symb {
    flex: 0 0 15px;
    max-width: 16px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        
    }
}
.menu_item_text {
    flex: 1;
}
.menu_item_arrow {
    flex: 0 0 auto;
}
.menu_line {
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid $color-light-gray-input;
}
.menu_footer {
    margin-top: 15px;
    font-size: 23px;
    line-height: 150%;
    font-weight: 700;
    color: rgba(36, 37, 38, 0.4);
    margin-bottom: 100px;
}
.menu_footer_ios {
    margin-top: 15px;
    font-size: 23px;
    line-height: 150%;
    font-weight: 700;
    color: rgba(36, 37, 38, 0.4);
    margin-bottom: 140px;
}
.menu_footer_dot {
    color: $color-cn-pink;
}

.hide.hide.hide.hide {
    display: none;
}
