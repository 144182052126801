$color-primary:#F35597;
$color-primary-hover:#D20F60;
$color-secondary: #574B90;
$color-black-gun-metal: #1E2A3B;
$color-secondary-hover:#39315E;
$color-bright-blue: #4C35BC;
$color-success: #6FCF97;
$color-danger: #F26659;
$color-black:#242526;
$color-skin-tone: #EED5C2;
$color-dark: #807D82;
$color-light-gray: #EBEAEB;
$color-subtle-gray: #B3B1B4;
$color-subtle-white: #FCFCFC;
$color-white: #FFFFFF;
$color-transparent: transparent;
$color-light-gray-daterange: #F8F7F7;
$color-light-gray-DRhover: #E7E4E4;
$color-light-gray-bw-border: #DCDADC;
$color-Shade-gray: #F2F1F2;
$color-shade-gray: #EEEEF0;
$color-dark-grey: #797882;
$color-grey-cultured: #F0F0EC;
$color-bright-purple: #4C35BC;
$color-dark-purple: #3A3B6A;
$color-light-gray-input: #E9E9EB;
$color-gray-font: #797882;
$color-gray-shade: #F0F0F0;
$color-light-gray-shade: #F7F7F7;
$color-subtle-grey: #ACABB4;
$color-subtle-light-grey: #C1C0C7;
$color-light-pink: #F75196;
$color-romantic-pink: #EF5697;
$color-pink-tone: #FFBED4;
$color-light-bg: #EDF2EF;
$color-error-red: #D83232;
$color-purple: #7868C7;
$color-deep-purple: #5F4BBC;
$color-field-grey: #DFE2E5;
$color-field-grey-hover: #D7DBDE;
$color-black-grey: #555759;
$color-white-background: #F0F1F2;
$color-pure-black: #000000;
$color-champagne-pink: #FDE9E0;
$color-sea-green: #2B8A4B;
$color-green-tree: #4C9AA5;
$color-light-sea-green: #D8EE9B;
$color-cn-pink: #F35597;
$color-vibrant-pink: #E86993;//
$color-vibrant-pink2: #F30C78;
$color-prussian-blue: #27364B;
$color-slate-grey: #64748B;
$color-gray-hp: #797882;
$color-pastel-red: #E76B74;
$color-pale-pink: #FEEFEF;
$color-bright-red: #DA1414;
$color-green: #0ba300;
$color-custom-blue: #277cfa;
$color-moderate-orange: #efb008;
$color-yellow-light: #FCF2C5;
$color-yellow-moderate: #FFDE81;
$color-rigid-red: #f0574b;
$color-gray-tollfree: #ABABAB;
$color-grey-rare: #1E2A3B;
$color-grey-divider: #E1E3E6;
$color-grey-pure: #D9D9D9;
$color-deep-black: #0F1A2A;
$color-sad-purple: #7869C7;
$color-light-purple: #EDECFF;
$color-light-black: #464646;
$color-oxford-blue: #0F294D;
$color-subtle-blue: #F0F8FF;
$color-azure-blue: #EAFAFF;
