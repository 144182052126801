@import '/src/styles/settings/colors';

.dot_container {
    --dot-size: 7px;
    --dot-color: #FFFFFF;
    --dot-margin: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dot_one,
.dot_two,
.dot_three {
    position: relative;
    left: -10000px;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: calc(var(--dot-size)/2);
    background-color: var(--dot-color);
    color: var(--dot-color);
    box-shadow: 10000px 0 0 calc(var(--dot-size)/2*-1) var(--dot-color);
    animation: dotAnim 1.4s infinite linear;
}
.dot_one {
    animation-delay: 0s;
}
.dot_two {
    animation-delay: .25s;
    margin-left: var(--dot-margin);
}
.dot_three {
    animation-delay: .5s;
    margin-left: var(--dot-margin);
}

@keyframes dotAnim {
  0% {
    box-shadow: 10000px 0 0 calc(var(--dot-size)/2*-1) var(--dot-color);
  }
  30% {
    box-shadow: 10000px 0 0 calc(var(--dot-size)/4) var(--dot-color);
  }
  60%,
  100% {
    box-shadow: 10000px 0 0 calc(var(--dot-size)/2*-1) var(--dot-color);
  }
}
