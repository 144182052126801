@import '/src/styles/settings/colors';
@import '/src/styles/generic/triangle';

.container {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: $color-white;
    max-width: 312px;
    width: 312px;
    padding: 20px 28px;
    border-radius: 15px;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    transform: translateY(-10px);
    opacity: 0;
    transition: opacity 0.4s ease;
    transition: transform 0.4s ease;
}
.visible {
    transform: translateY(0px);
    opacity: 1;
}
.container.mobile {
    padding: 10px 16px;
    width: 220px;
    border-radius: 10px;
    top: 66px;
    right: 9px;
}
.close {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    svg > path {
        stroke: rgb(36, 37, 38);
    }
}
.close:hover {
    cursor: pointer;
}
.header {
    position: relative;
    display: flex;
    margin-bottom: 16px;
}
.header_symb {
    flex: 0 0 auto;
    position: absolute;
    top: 0;
    left: 0;
}
.header_title {
    margin-left: 36px;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
}
.body {
    color: $color-gray-font;
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    text-align: center;
}
.body_name {
    color: $color-cn-pink;
}
.triangle {
    background-color: $color-white;
    position: absolute;
    top: -9px;
    right: 17px;
}

.mobile {
    .header {
        margin-bottom: 6px;
        margin-right: 20px;
    }
    .header_symb {
        top: -6px;
    }
    .header_symb > svg {
        transform: scale(0.66);
    }
    .header_title {
        font-size: 12px;
    }
    .close {
        top: 4px;
    }
    .body {
        font-size: 10px;
    }
}