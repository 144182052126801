@import '/src/styles/settings/colors';

.container {
    font-family: inherit;
    font-size: 14px;
    line-height: 21px;
    background-color: $color-white;
    // position: absolute;
    border-radius: 15px;
    border: 0.5px solid $color-field-grey;
    padding: 20px;
    width: max-content;
    right: 0;
    top: 0.5rem;
    
.header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr 0;
    column-gap: 8px;
    .header__pic {
        grid-row: 1/4;
        grid-column: 1;
    }
    .header__name {
        cursor: default;
        grid-row: 1;
        grid-column: 2;
    }
    .header__email {
        cursor: default;
        grid-row: 2;
        grid-column: 2;
        color: $color-subtle-grey;
    }
    .header__email:hover + .tooltip_wrapper {
        opacity: 1;
        visibility: 1;
    }
    .tooltip_wrapper {
        pointer-events: none;
        height: 0;
        opacity: 0;
        visibility: 0;
        grid-row: 3;
        position: relative;
        transition: all 0.3s ease;
    }
    .tooltip {
        position: absolute;
        top: 4px;
        left: 16px;
        padding: 10px 16px;
        width: 234px;
        background-color: $color-white;
        border-radius: 5px;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.06), -1px -2px 6px rgba(0, 0, 0, 0.06);
    }
    .arrow_up {
        position: absolute;
        top: -9px;
        left: 18px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color-white;
    }
    .tooltip__symbol {
        position: relative;
        top: 3px;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 4px;
    }
    .tooltip__text {
        font-size: 10px;
        line-height: 150%;
        font-weight: 400;
        color: $color-gray-font;
    }
}

ul {
    cursor: pointer;

    &.menu {
        margin-top: 20px;
        list-style-type: none;
        display: block;
        .mitem {
            min-width: 210px;
            margin: 0;
            .mlink {
                padding: 12px 24px;
                border-radius: 108px;
                display: flex;
                font-size: inherit;
                line-height: inherit;
                font-variant: unset;
                text-transform: none;
                align-items: unset;
                font-feature-settings: unset;
                color: unset;
                cursor: unset;
                max-height: 45px;
                overflow: hidden;
    
                &:link, &:active, &:visited {
                    color: inherit;
                }
    
                &:hover {
                    background-color: $color-light-gray-shade;
                    cursor: pointer;
                }
                .mlink__svg {
                    flex: 0 0 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    svg:not(.staticSvg) {
                        margin: 0;
                        path {
                            fill:#64748B;
                        }
                    }
                }
                .mlink__text {
                    flex: 0 0 auto;
                    margin-left: 14px;
                }
                &.disabled {
                    opacity: 0.8;
                    background-color: $color-light-gray-shade;
                    cursor: not-allowed;
                }
            } 
            
        }
        
    }
    
}

}
