@import '/src/styles/settings/colors';

@property --btn-angle-prop {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}
@property --btn-color-perc {
    syntax: "<length-percentage>";
    initial-value: 0%;
    inherits: false;
}


.wrapper {
    background-color: $color-white;
}

.content {
    position: relative;
    z-index: 0;
}

.top {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 88px 0;

    .container {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // column-gap: 2%;

        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin: 50px 0;

            // width: 100%;
            .title_text {
                padding: 0 30px;
                // max-width: 600px;
                color: $color-pure-black;
                font-size: 80px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;

                >span {
                    color: $color-vibrant-pink2;
                }
            }
        }

        .pics_wrapper {
            position: relative;
            .fade_left {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                height: 100%;
                width: 60px;
                background: linear-gradient(90deg, white 0, transparent 100%);
            }
            .fade_right {
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                height: 100%;
                width: 60px;
                background: linear-gradient(270deg, white 0, transparent 100%);
            }
        }

        .pics {
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // column-gap: 8%;
            padding: 0 60px 130px 60px;
            display: flex;
            // flex-direction: row-reverse;
            width: 100%;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .pics_col1 {
                .pic {
                    img {
                        object-position: 74% 50%;
                    }
                }
            }

            .pics_col1,
            .pics_col2 {
                display: flex;
                // flex-direction: column;
                // justify-content: center;
                // align-items: center;

                .pic {
                    position: relative;
                    overflow: hidden;
                    height: 446px;
                    width: 318px;
                    // height: 26vw;
                    // width: 18vw;
                    // max-height: 386px;
                    // max-width: 276px;
                    margin-right: 15px;
                    background-color: lightgray;
                    border-radius: 15px;
                    border: 2px solid var(--whites-bright-white, #FFF);
                    box-shadow: 0px 50px 80px -41px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    .message {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2%;
        // margin-top: 50px;
        padding: 0 60px;

        .message_left {
            .message_left_title {
                color: $color-pure-black;
                font-size: 54px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;

                span {
                    color: $color-cn-pink;
                }
            }

            .message_left_subtitle {
                color: $color-pure-black;
                font-size: 23px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-top: 32px;
            }

            .message_left_btn {
                >a {
                    display: inline-block;
                    width: 218px;
                    text-align: center;
                    border-radius: 30px;
                    padding: 10px 0;
                    margin: 32px 0;
                    background: linear-gradient(92deg, #F35597 1.79%, #7868C7 99.81%);
                    // background: linear-gradient(var(--btn-angle-prop), #F35597 0%, #F35597 var(--btn-color-perc), #7868C7 100%);
                    // transition: --btn-angle-prop 1s, --btn-color-perc 1s;
                    // background: var(--new-gradient, linear-gradient(132deg, #F35597 0%, #7868C7 100%));

                    color: $color-white;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    letter-spacing: 0.16px;
                }

                // >a:hover {
                //     --btn-angle-prop: 166deg;
                //     --btn-color-perc: 26%;
                // }
            }
        }

        .message_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                color: #6E6E73;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }
    }
}

.middle {
    margin-bottom: 60px;
}

.part {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 160px 60px;
    position: relative;

    .part_inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3%;

        .title {
            grid-column: 1;
            grid-row: 1;
            font-size: 54px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            color: $color-white;
        }

        .text {
            grid-column: 2;
            grid-row: 1;
            color: $color-white;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;

            p {
                margin-bottom: 20px;

                >a {
                    color: $color-white;
                    text-decoration: underline;
                }
            }
        }

        .addendum {
            grid-column: 2;
            grid-row: 2;
            display: flex;
            justify-content: center;
            align-items: center;

            .addendum_text {
                position: relative;
                width: 80%;
                border-radius: 15px;
                padding: 20px;
                border: 0.5px solid rgba(100, 116, 139, 0.50);
                background: var(--blues-ghost-white, #F4F8FF);
                box-shadow: 0px 60px 65px -50px rgba(0, 0, 0, 0.20);

                color: var(--pinks-light-coral, #FC9595);
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }

            .addendum_check {
                height: 100%;
                display: flex;
                align-items: center;
                position: absolute;
                top: 0;
                left: -36px;
            }
        }
    }

    .asset {
        max-width: 100vw;
        overflow: hidden;
    }

    &1 {
        z-index: 3;
        background-color: $color-purple;

        .part_inner {
            .title {
                >span {
                    color: #FFBED4;
                }
            }
        }

        .asset {
            position: absolute;
            bottom: -25px;
            left: 0;
        }
    }

    &2 {
        z-index: 2;
        background-color: $color-vibrant-pink;

        .part_inner {
            .title {
                grid-column: 2;
                grid-row: 1;

                >span {
                    color: $color-bright-blue;
                }
            }

            .text {
                grid-column: 1;
                grid-row: 1;
            }

            .addendum {
                grid-column: 1;
                grid-row: 2;
            }
        }

        .asset {
            position: absolute;
            bottom: -56px;
            right: 0;
        }
    }

    &3 {
        z-index: 1;
        background-color: #FFB199;

        .part_inner {
            .title {
                >span {
                    color: #EA526F;
                }
            }
        }

        .asset {
            position: absolute;
            bottom: 20%;
            left: -4%;
        }
    }
}


@media screen and (max-width: 1100px) {
    .top {
        padding-top: 88px;
        padding-bottom: 60px;

        .container {
            .title {
                margin: 40px 0;

                .title_text {
                    padding: 0;
                    font-size: 48px;
                }
            }
            .pics {
                padding: 0 50px 60px 50px;

                .pics_col1,
                .pics_col2 {
                    .pic {
                        height: 262px;
                        width: 188px;
                        box-shadow: 0px 50px 50px -41px rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }

        .message {
            padding: 0 50px;
            grid-template-columns: 1fr;

            .message_left {
                display: flex;
                flex-direction: column;
                align-items: center;

                .message_left_title {
                    font-size: 36px;
                }

                .message_left_subtitle {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                    margin-top: 20px;
                }

                .message_left_btn {
                    >a {
                        width: 160px;
                        margin: 20px 0;
                    }
                }
            }

            .message_right {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                p {
                    text-align: center;
                    margin-bottom: 28px;
                }
            }
        }
    }

    .part {
        padding: 50px 50px 160px 50px;

        .part_inner {
            column-gap: 2%;

            .title {
                grid-column: 1/-1;
                grid-row: 1;
                font-size: 34px;
            }

            .text {
                grid-column: 1/-1;
                grid-row: 2;
                font-size: 24px;
                margin: 50px 0;
            }
        }

        &1 {
            .part_inner {
                .addendum {
                    grid-column: 2;
                    grid-row: 3;
                    justify-content: flex-end;
                }
            }

            .asset {
                svg {
                    transform: scale(0.7) translate(-18%, 20%);
                }
            }
        }

        &2 {
            padding-bottom: 50px;

            .part_inner {
                .title {
                    text-align: end;
                }

                .text {
                    p:last-child {
                        max-width: 48%;
                        margin-bottom: 0;
                    }
                }

                .addendum {
                    grid-column: 1;
                    grid-row: 3;
                }
            }

            .asset {
                svg {
                    transform: scale(0.7) translate(32%, 20%);
                }
            }
        }

        &3 {
            padding-bottom: 50px;

            .part_inner {
                .title {}

                .text {
                    grid-column: 1/-1;
                    grid-row: 2;

                    p:nth-child(1),
                    p:nth-child(2) {
                        max-width: 48%;
                        margin-left: auto;
                    }

                    p:nth-child(3) {
                        margin-top: 14%;
                    }

                    p:last-child {
                        margin-bottom: 0;
                    }
                }

                .addendum {
                    grid-column: 1/-1;
                    grid-row: 3;
                }
            }

            .asset {
                bottom: unset;
                top: 0;

                svg {
                    transform: scale(0.5) translate(-46%, -12%);
                    // transform: scale(0.7) translate(-18%, 20%);
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .top {
        padding: 78px 0 0 0;
        // padding: 78px 30px 0 30px;

        .container {
            margin-top: 30px;
            grid-template-columns: 1fr;
            row-gap: 26px;

            .pics_wrapper {
                .fade_left,
                .fade_right {
                    display: none;
                }
            }

            .pics {
                padding: 0 18px 30px 18px;
                grid-row: 2;
                display: grid;
                grid-template-columns: 1fr;
                row-gap: 6px;
                max-width: 100vw;
                width: 100vw;
                padding-bottom: 40px;
                overflow: hidden;

                .pics_col1 {
                    grid-row: 2;
                    // transform: translateX(-16%);
                    justify-self: center;
                }

                .pics_col2 {
                    grid-row: 1;
                    justify-self: center;
                    // transform: translateX(-16%);
                }

                .pics_col1,
                .pics_col2 {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;

                    .pic {
                        position: relative;
                        height: 150px;
                        width: 110px;
                        max-height: 150px;
                        min-width: 108px;
                        max-width: 180px;
                        margin: 0;
                        // margin: 0 3px;
                        background-color: lightgray;
                        border-radius: 15px;
                        border: 2px solid var(--whites-bright-white, #FFF);
                        box-shadow: 0px 20px 60px -30px rgba(0, 0, 0, 0.25);
                    }
                }
            }

            .title {
                margin: 30px 0;
                grid-row: 1;

                .title_text {
                    padding: 0;
                    font-size: 24px;
                }
            }
        }

        .message {
            margin-top: 0;
            padding: 0 30px;
            grid-template-columns: 1fr;

            .message_left {
                .message_left_title {
                    font-size: 23px;
                    font-weight: 600;
                }

                .message_left_subtitle {
                    margin-top: 16px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: 0.14px;
                }

                .message_left_btn {
                    >a {
                        margin: 16px 0;
                        width: 160px;
                        margin: 20px 0;
                    }
                }
            }

            .message_right {
                margin-bottom: 10px;

                p {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: 0.12px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .part {
        padding: 30px;

        .part_inner {
            .title {
                font-size: 28px;
                font-weight: 600;
            }

            .text {
                font-size: 16px;
                font-weight: 500;
                margin: 30px 0;

                p:last-child {
                    margin-bottom: 0;
                }
            }

            .addendum.addendum {
                grid-column: 1/-1;
                grid-row: 3;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                justify-content: center;
            }
        }

        &1 {
            padding-bottom: 260px;

            .part_inner {
                .addendum {}
            }

            .asset {
                svg {
                    transform: scale(0.6) translate(-26%, 30%);
                }
            }
        }

        &2 {
            padding-bottom: 350px;

            .part_inner {
                .title {
                    text-align: center;
                }

                .text {
                    p:last-child {
                        max-width: unset;
                        margin-bottom: 0;
                    }
                }
            }

            .asset {
                svg {
                    transform: scale(0.56) translate(-26%, 32%);
                }
            }
        }

        &3 {
            .part_inner {
                .title {}

                .text {
                    margin-top: 360px;
                    grid-column: 1/-1;
                    grid-row: 2;

                    p:nth-child(1),
                    p:nth-child(2) {
                        max-width: unset;
                        margin-left: unset;
                    }

                    p:nth-child(3) {
                        margin-top: unset;
                    }

                    p:last-child {
                        margin-bottom: 0;
                    }
                }

                .addendum {
                    // grid-column: 1/-1;
                    // grid-row: 3;
                }
            }

            .asset {
                top: 0;
                left: 0;

                svg {
                    transform: scale(0.44) translate(-42%, -40%);
                    // transform: scale(0.7) translate(-18%, 20%);
                }
            }
        }
    }
}

@media screen and (max-width: 421px) {
    .part {
        &1 {
            padding-bottom: 230px;
            .asset {
                // height: 200px;
                // width: 350px;
                svg {
                    // width: 100%;
                    // height: 100%;
                    // transform: none;
                    transform: scale(0.5) translate(-45%, 45%);
                }
            }
        }
        &3 {
            // padding-bottom: 230px;
            .asset {
                svg {
                    transform: scale(0.44) translate(-50%, -28%);
                }
            }
        }
    }
}

@media screen and (min-width: 1100px) and (max-width: 1280px) {
    .part {
        &2 {
            padding-bottom: 230px;
            .asset {
                svg {
                    transform: translate(10%, 0);
                }
            }
        }
        &3 {
            .asset {
                svg {
                    transform: scale(0.9) translate(-8%, -6%);
                }
            }
        }
    }
}

@media screen and (min-width: 640px) and (max-width: 740px) {
    .middle {
        .part {
            .asset {}

            &2 {
                .asset {
                    svg {
                        transform: scale(0.6) translate(42%, 28%);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 600px) and (max-width: 640px) {
    .middle {
        .part {
            .asset {}

            &2 {
                .asset {
                    svg {
                        transform: scale(0.6) translate(30%, 28%);
                    }
                }
            }
            &3 {
                .asset {
                    svg {
                        transform: scale(0.44) translate(-56%, -12%);
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 800px) and (max-width: 1100px) {
    .middle {
        .part {
            .asset {}

            &3 {
                .asset {
                    svg {
                        transform: scale(0.5) translate(-26%, -12%);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .middle {
        .part {
            .asset {
                overflow: unset;
            }

            &1 {
                .asset {
                    svg {
                        transform: translate(10%, 0);
                    }
                }
            }

            &2 {
                .asset {
                    svg {
                        transform: translate(-10%, 0) scale(0.95);
                    }
                }
            }

            &3 {
                .asset {
                    svg {
                        transform: translate(16%, 10%);
                    }
                }
            }
        }
    }
}



.banner {
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;

    .banner_inner {
        background-color: #47C1BF;
        border-radius: 20px;
        padding: 30px 60px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 340px;
    }

    .banner_left {
        // padding-right: 30px;
        grid-column: 2;
        grid-row: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .banner_title {
            color: $color-white;
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }

        .banner_subtitle {
            color: $color-white;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-top: 8px;
        }

        .banner_btn {
            >a {
                display: inline-block;
                width: 200px;
                text-align: center;
                border-radius: 10px;
                padding: 8px 0;
                margin-top: 30px;
                // margin: 32px 0;
                background: linear-gradient(92deg, #F35597 1.79%, #7868C7 99.81%);
                // background: linear-gradient(var(--btn-angle-prop), #F35597 0%, #F35597 var(--btn-color-perc), #7868C7 100%);
                // transition: --btn-angle-prop 1s, --btn-color-perc 1s;
                // background: var(--new-gradient, linear-gradient(132deg, #F35597 0%, #7868C7 100%));

                color: $color-white;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.14px;
            }

            // >a:hover {
            //     --btn-angle-prop: 166deg;
            //     --btn-color-perc: 26%;
            // }
        }
    }

    .banner_right {
        grid-column: 1;
        grid-row: 1;

        .banner_pic {
            height: 100%;
            display: flex;
            justify-content: center;

            >svg {
                height: 100%;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }

    @media screen and (max-width: 1136px) {
        padding: 0 30px;
        margin: 60px auto;

        .banner_inner {
            grid-template-rows: 240px;
            padding: 10px 30px;
            grid-template-columns: 30% 70%;
        }

        .banner_left {
            .banner_title {
                font-size: 28px;
            }

            .banner_subtitle {
                font-size: 18px;
                font-weight: 400;
            }

            .banner_btn {
                >a {
                    width: 140px;
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        padding: 0 20px;

        .banner_inner {
            padding: 30px 20px;
            grid-template-columns: 60% 40%;
            grid-template-rows: 240px;
        }

        .banner_left {
            grid-column: 1;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            .banner_title {
                font-size: 28px;
            }

            .banner_subtitle {
                margin-top: 0;
                font-size: 18px;
                font-weight: 400;
            }

            .banner_btn {
                >a {
                    margin: 0;
                }
            }
        }

        .banner_right {
            grid-column: 2;

            .banner_pic {
                transform: scale(1.4);
                // height: 140%;
                // width: 150%;
            }
        }
    }

    @media screen and (max-width: 460px) {
        .banner_right {
            grid-column: 2;

            .banner_pic {
                transform: scale(1.4) translate(-10%, 8%);
            }
        }
    }
}