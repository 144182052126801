@import '/src/styles/settings/colors';

.omnisearch_aside.omnisearch_aside.omnisearch_aside {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 4px;
    width: 120px;
    // width: unset;
}

.omnisearch_stars {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content !important;
    border-radius: 5px;
    border: 1px solid #E9E9EB;
    margin-top: 5px;
    padding: 0px 6px;
    gap: 3px;
    color: $color-gray-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.12px;
}
.omnisearch_stars_mob {
    border: 1px solid $color-light-gray-input;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 16px 16px;
    .omnisearch_stars_mob_num {
        font-size: 14px;
        line-height: 20px;
        color: $color-gray-font;
        text-align: center;
    }
    svg {
        position: absolute;
        top: 4px;
        transform: scale(1.34);
    }
}

.omnisearch_grs {
    margin-top: 6px;
    font-size: 12px;
    line-height: 150%;
    font-weight: 500;
    display: flex;
    .omnisearch_grs_number {
        color: $color-white;
        background-color: $color-sad-purple;
        padding: 0 3px;
        text-align: center;
        width: 35px;
        flex-shrink: 0;
        border-radius: 30px;
        border: 1px solid #7868C7;
    }
    .omnisearch_grs_text {
        flex: 0 0 auto;
        margin-left: 4px;
        color: $color-pure-black;
        font-weight: 500;
    }
}

@media screen and (max-width: 600px) {
    .omnisearch_aside.omnisearch_aside.omnisearch_aside {
        width: unset;
    }
}